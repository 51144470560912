<script setup>
const props = defineProps({
  mate: {
    type: Object,
    required: true,
  },
  whatsapp: {
    type: String,
    required: true,
  }
})
const router = useRouter();
const routeToTeammateShow = () => {
  const localePath = useLocalePath()
  router.push(localePath({ name: 'slug', params: { slug: props.mate.slug } }))
}
</script>
<template>
  <div class="flex items-center justify-between gap-4 pb-3 cursor-pointer" @click="routeToTeammateShow">
    <div class="flex items-center gap-3">
      <img :src="mate.picture" :alt="`${mate.name} ${mate.position}`" class="rounded-full w-16 h-16 object-cover">
      <div class="flex flex-col">
        <h3 class="opacity-80 text-black text-xl font-medium">{{ mate.name }}</h3>
        <p class="opacity-80 text-gray-500 text-sm mt-1">{{ mate.position }}</p>
      </div>
    </div>
    <div class="ms-auto flex gap-6">
      <a :href="whatsapp"><i class="fa-brands fa-xl fa-fw fa-whatsapp"></i></a>
      <a href=""><i class="fa-regular fa-xl fa-fw fa-envelope"></i></a>
    </div>
  </div>
</template>